<script setup>
import gsap from '~/vendors/gsap'
import SplitType from 'split-type'
import GlobalEmitter from '~/glxp/utils/emitter'
import { onLoadAnimationEnd } from '~~/composables/onLoadAnimationEnd'

import {
  eyebrowAnimation,
  headlineAnimation,
  bodyCopyAnimation,
  bodyCopyAnimationFast,
  dividerAnimationCenter,
  dividerAnimationLeft,
} from '~/utils/animations'

const props = defineProps({
  alignment: {
    type: String,
    default: 'center',
    validator(value) {
      // The value must match one of these strings
      return ['left', 'center'].includes(value)
    },
  },
  date: {
    type: String,
    default: null,
  },
  eyebrow: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  copy: {
    type: [Object, String],
    default: null,
  },
  level: {
    type: String,
    default: 'h2',
    validator(value) {
      // The value must match one of these strings
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
    },
  },
  size: {
    type: String,
    default: 'l',
    validator(value) {
      // The value must match one of these strings
      return ['xs', 's', 'm', 'l'].includes(value)
    },
  },
  animate: {
    type: Boolean,
    default: false,
  },
  animateOnReveal: {
    type: String,
    default: null,
  },
  smallMobileDivider: {
    type: Boolean,
    default: false,
  },
  startDate: {
    type: String,
    default: null,
  },
  endDate: {
    type: String,
    default: null,
  },
  enableSidePanel: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const slug = computed(() => route.params.slug)
const copyType = computed(() => typeof props.copy)

/**
 * Animation on load
 */
const isActive = ref(false)
const hasAnimated = ref(false)
const el = ref(null)
let tl

function onReveal() {
  isActive.value = true
}

function onUnreveal() {
  isActive.value = false
}

watch(isActive, (value) => {
  if (value) {
    tl?.timeScale(1).play(0)
  } else {
    tl?.timeScale(3).reverse(tl.duration())
  }
})

async function initTimeline() {
  if (tl) {
    tl.seek(tl.duration())
    hasAnimated.value = true
    tl.kill()
  }
  if ((props.animate && !hasAnimated.value) || props.animateOnReveal) {
    const eyebrow = el.value.querySelector('.section-header__eyebrow')
    const divider = el.value.querySelector('.section-header__divider')
    const headline = el.value.querySelector('.section-header__headline')
    const copy = el.value.querySelectorAll('.section-header__copy .line')

    let counter = 0

    tl = gsap.timeline({ paused: true })

    if (eyebrow) {
      const { chars: eyebrowChars } = new SplitType(eyebrow, { types: 'chars' })
      tl.from(eyebrowChars, eyebrowAnimation, counter * 0.25)
      counter++
    }

    if (headline) {
      const { lines: headlineLines } = new SplitType(headline, {
        types: 'lines',
      })
      tl.from(headlineLines, headlineAnimation, counter * 0.25)
      counter++
    }

    if (divider) {
      const isCentered = divider.classList.contains('horizontal-rule--center')
      tl.from(
        divider,
        isCentered ? dividerAnimationCenter : dividerAnimationLeft,
        counter * 0.25
      )
      counter++
    }

    if (copy.length) {
      if (copy.length > 20) {
        tl.from(copy, bodyCopyAnimationFast, counter * 0.25)
      } else {
        tl.from(copy, bodyCopyAnimation, counter * 0.25)
      }
    }

    tl.call(() => (hasAnimated.value = true))

    if (!!props.animateOnReveal && isActive.value) {
      tl.seek(tl.duration())
    }
  }
}

onMounted(async () => {
  await document.fonts.ready
  initTimeline()
  GlobalEmitter.on('debouncedResize', initTimeline)
  if (!!props.animateOnReveal) {
    GlobalEmitter.on(`reveal:${props.animateOnReveal}`, onReveal)
    GlobalEmitter.on(`unreveal:${props.animateOnReveal}`, onUnreveal)
  }
})

onLoadAnimationEnd(() => {
  if (props.animate) {
    tl.play()
  }
})

onBeforeUnmount(() => {
  tl?.kill()
  GlobalEmitter.off('debouncedResize', initTimeline)
  if (!!props.animateOnReveal) {
    GlobalEmitter.off(`reveal:${props.animateOnReveal}`, onReveal)
    GlobalEmitter.off(`unreveal:${props.animateOnReveal}`, onUnreveal)
  }
})
</script>

<template>
  <header
    ref="el"
    class="section-header"
    :class="[`section-header--${size}`, `section-header--${alignment}`]"
  >
    <span v-if="eyebrow" class="section-header__eyebrow text__eyebrow">
      {{ eyebrow }} <span v-if="date">• {{ date }}</span>
    </span>
    <span v-else-if="date" class="section-header__eyebrow text__eyebrow">
      {{ date }}
    </span>

    <HorizontalRule
      v-if="eyebrow && !copy"
      class="section-header__divider"
      :center="alignment === 'center'"
      :mobile-small="smallMobileDivider"
    />
    <component
      :is="level"
      class="section-header__headline text--gradient"
      :class="{
        'text__headline-1': size === 'l',
        'text__headline-2': size === 'm',
        'text__headline-3': size === 'xs' || size === 's',
        'text__headtitle-3': size === 'x'
      }"
      v-html="title"
    >
    </component>

    <HorizontalRule
      center
      v-if="copy && !eyebrow"
      class="section-header__divider"
    />

    <template v-if="copy">
      <ContentfulRichText
        v-if="copyType === 'object'"
        class="section-header__copy"
        :class="{
          text__body: size === 'xs',
          'text__copy-medium': size === 's',
        }"
        :json="copy.json"
        split
      />
      <SplitTextBlock
        v-else-if="copyType === 'string'"
        class="section-header__copy"
      >
        {{ copy }}
      </SplitTextBlock>
    </template>
    <span v-if="startDate" class="section-header__eyebrow text__eyebrow policy-number">
      Start Date : {{ startDate }}
     </span>
     <span v-if="endDate" class="section-header__eyebrow text__eyebrow policy-number">
      End Date : {{ endDate }}
     </span>
    <span v-if="route.path == '/cookie-notice-staging' || route.path == '/cookie-notice'" class="policy-number">
      Policy number: VG2000-CNP-001
    </span>
  </header>
</template>

<style lang="scss">
// Vendors
@import 'styles/vendors/sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/functions';

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__divider {
    margin-top: -6px !important;
    margin-bottom: 0;

    @include mq($from: 'l') using ($from) {
      margin-top: 14px !important;
    }
  }

  &__eyebrow {
    @include mq($until: 'm') using ($until) {
      margin-bottom: 8px;
    }
  }

  &__divider + &__headline {
    margin-top: 41px;

    @include mq($until: 'm') using ($until) {
      margin-top: 28px;
    }
  }

  &__headline b {
    display: flex;
    align-items: center;
  }
  &__headline div {
    opacity: 1 !important;
    transform: none !important;
  }

  &--l {
    &.section-header--left {
      .section-header__eyebrow {
        margin-left: 6px;
      }

      .section-header__divider {
        margin-left: 8px;
      }
    }
  }

  &--m {
    &.section-header--center {
      .section-header__divider {
        margin-top: 18px;
      }

      .section-header__divider + .section-header__headline {
        margin-top: 38px;
      }
    }

    &.section-header--left {
      .section-header__eyebrow {
        margin-left: 4px;
      }

      .section-header__divider {
        margin-left: 4px;
      }
    }
  }

  &--s,
  &--xs {
    .section-header__eyebrow + .section-header__headline {
      margin-top: 28px;

      @include mq($from: 'l') using ($from) {
        margin-top: 34px;
      }
    }
  }

  &--s {
    .section-header__eyebrow {
      @include mq($until: 'l') using ($until) {
        + .section-header__headline {
          margin-top: 10px;
        }
      }
    }
    .section-header__divider {
      @include mq($from: 'l') using ($from) {
        margin-top: 10px;
      }

      @include mq($until: 'l') using ($until) {
        + .section-header__headline {
          margin-top: 28px;
        }
      }
    }

    .section-header__copy {
      margin-top: 42px;
    }

    &.text__headline-3 {
      @include mq($until: 'l') using ($until) {
        font-size: 52px;
        line-height: 1.03;
      }
    }
    &.text__headtitle-4 {
      @include mq($until: 'l') using ($until) {
        font-size: 40px;
        line-height: 1.4;
      }
    }
  }

  &--xs {
    .section-header__divider {
      margin-top: 8px;
    }

    .section-header__copy {
      margin-top: 34px;
    }
  }

  &--left {
    align-items: flex-start;

    // .section-header__headline b {
    //   &::before {
    //     display: none;
    //   }

    //   &::after {
    //     margin-left: 42px;
    //   }
    // }
  }

  &--center {
    .section-header__eyebrow,
    .section-header__headline,
    .section-header__copy {
      text-align: center;
    }
  }
}
.policy-number{
  margin-top: 20px;
}
</style>
